<template>
	<label class="checkbox" >
		<input type="checkbox" @input="changed" class="checkbox__input" checked />
		<span class="checkbox__box"></span>
		<slot></slot>
	</label>
</template>

<script>
export default {
	name: "CheckBox",
	props: ["value"],
	methods: {
		changed() {
			this.$emit("changed", !this.value);
		},
	},
};
</script>

<style scoped lang="scss">
@import "../styles/constants.scss";

.checkbox {
	display: flex;
	white-space: nowrap;
	align-items: center;
	color: #0000;
	cursor: pointer;

	&__input {
		position: absolute;
		appearance: none;
		-webkit-appearance: none;

		&:focus + .checkbox__box {
			border: 1px solid rgba(187, 187, 187, 1);
		}
	}

	&__input:checked + .checkbox__box::after {
		background-image: url("../images/utils/galka.svg");
		background-size: contain;
		background-repeat: no-repeat;
		width: 17px;
		height: 13px;
		position: absolute;
		left: 0px;
		top: -1px;
		content: "";
		display: block;
	}

	&__box {
		position: absolute;
		width: 15px;
		left: 1px;
		height: 15px;
		border: 1px solid #e0e0e0;
		background-color: #fff;
	}
}

</style>
