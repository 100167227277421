<template>
	<div :class="[device_platform]" class="block-get get modal">
		<div class="blacking" @click="hideSelf"></div>
		<div class="get__container">
			<div class="get__body">
				<img
					src="../images/utils/close.svg"
					alt="Закрыть"
					class="get__close"
					width="24"
					height="24"
					@click="hideSelf"
				/>
				<h3 class="get__title font_type_block-title" v-html="data?.title ? data?.title : 'Оставьте свой номер, и мы свяжемся с вами!'"></h3>
				<!-- <h4 class="get__subtitle" v-html="data?.subtitle ? data?.subtitle : 'Оставьте заявку, и получите лучшее предложение на покупку SUZUKI в Автомир!'"></h4> -->
				<callback-input
					v-bind="$attrs"
					:form_class="'popup'"
					:focusOnMount="true"
					:data="data"
				>
					{{data?.button ? data?.button : 'Отправить заявку'}}
				</callback-input>
			</div>

		</div>
	</div>
</template>

<script>
import CallbackInput from "./callback-form/callback-input.vue";
import Mixin from "../common/mixin";

export default {
	name: "full-get",
	components: { CallbackInput },
	emits: ['close'],
	mixins: [Mixin],
	props: ["data"],
	data() {
		return {
			phone: "",
			defaultImg: 'contacts',
		};
	},
	computed: {
	},
	methods: {
		hideSelf() {
			this.$emit("close");
		},
		getAgreement() {
			this.$emit("getAgreement");
		},
	},
};
</script>

<style lang="scss">
@import "../styles/constants.scss";

.get {
	width: 100%;
	display: flex;

	&__container {
		position: fixed;
		top: 50vh;
		left: 50vw;
		transform: translate(-50%, -50%);
		width: 100%;
		max-width: 95vw;
		display: flex;
		justify-content: center;
		z-index: 500;
		pointer-events: none;
	}

	&__title {
		font-size: 22px;
		line-height: 120%;
		margin: 15px 0;
	}
	&__subtitle {
		font-size: 22px;
		line-height: 120%;
		font-size: 16px;
		font-weight: 400;
		margin-bottom: 24px;
	}
	&__body {
		position: relative;
		max-width: 400px;
		width: 100%;
		padding: 24px 32px 32px 32px;
		background: #fff;
		color: #000;
		pointer-events: all;
		text-align: center;
	}
	&__image {
		pointer-events: all;
		display: flex;
	}

	&__img {
		max-width: 100%;
		max-height: 100%;
		object-fit: cover;
	}

	&__close {
		position: absolute;
		right: 12px;
		top: 12px;
		cursor: pointer;
	}
}

@media (max-width: $tablet-md) and (min-height: 550px) {
	.get {
		&__container {
			flex-direction: column;
			align-items: stretch;
			max-width: 400px;
		}
		&__body {
			max-width: 100%;
		}
		&__image {
			flex-shrink: 1;
		}
	}
}
@media (max-width: $mobile-md) {
	.get {

		&__container {
			top: 47vh;
			flex-direction: column;
			align-items: stretch;
			max-width: 400px;
		}
		&__body {
			max-width: 100%;
			// padding-right: 40px;
			padding: 16px 28px 20px;
		}
		&__image {
			flex-shrink: 1;
			max-height: 35vh;
		}
		&__close {
			top: 12px;
			right: 10px;
		}
	}
}

@media (max-width: $mobile-sm) {
	.get {
		&__container {
			max-width: calc(100vw - 16px);
		}
		.button {
			font-size: 16px;
		}
	}
}

@media (max-height: 550px) {
	.get {
		&__body {
			padding-right: 40px;
		}
		&__close {
			top: 12px;
			right: 10px;
		}

	}
}

</style>
