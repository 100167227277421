<template>
  <section class="waranty">
        <div class="waranty__images">
      <img src="../images/bacground/warranty.jpg" alt="" />
    </div>
        <div class="container">
      <div class="waranty__inner">
        <h2 class="waranty__title">Улучшим любое предложение</h2>
        <h3 class="waranty__subtitle">
          Гарантируем отличные условия покупки
        </h3>
        <callback-input
          v-bind="$attrs"
          :form_class="'waranty__form'"
          :focusOnMount="false"
          :data="{form:'warinty',type:'sale',}"
          :agreement__class="'waranty__agrement'"
        >
          {{ data?.button ? data?.button : "Узнать цену по акции" }}
        </callback-input>
      </div>
    </div>

  </section>
</template>

<script>
import CallbackInput from "./callback-form/callback-input.vue";
export default {
  props: ["data"],
  components: { CallbackInput },
};
</script>

<style lang="sass" scoped>
.waranty
    color: #fff
    padding-top: 80px
    height: calc( 100vw - 69vw )
    position: relative
    z-index: 1
    &__images
        position: absolute
        background-size: contain
        top: 0
        z-index: -1
        left: 0
        right: 0
        bottom: 0
    &__title
        font-size: 40px
        font-family: 'Hyundai Sans Head'
        line-height: 49px
    &__subtitle
        font-size: 30px
        font-weight: 400
        line-height: 37px
        margin-top: 10px
        margin-bottom: 61px
@media screen and (max-width: 1228px)
  .waranty
    padding-top: 40px
  .waranty__subtitle
    margin-bottom: 30px


@media screen and (max-width: 990px)
    .waranty__images
        position: static
    .waranty
        height: auto
        padding-top: 20px
        background-color: #fff
        padding-bottom: 30px
        margin-bottom: 30px
    .waranty__title
        color: #000
        font-size: 40px
        line-height: 49px
    .waranty__inner
        padding-top: 70px
        max-width: 622px
        margin: 0 auto
    .waranty__subtitle
        font-size: 30px
        line-height: 37px
        color: #000

    .waranty__form
      margin: 0 auto
@media screen and (max-width: 623px)
  .waranty__inner
    padding: 70px 10px 0px
@media screen and (max-width: 591px)
    .waranty__inner
        padding-top: 30px
        padding-left: 12px
        padding-right: 12px
    .waranty__title
        font-size: 24px
        line-height: 29px
        text-align: center
    .waranty__subtitle
        font-size: 20px
        line-height: 24px
        margin-bottom: 25px
        text-align: center
    .waranty
        height: 464px
</style>
