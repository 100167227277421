<template>
    <div :class="[device_platform]" class="full-slider get-container">
        <div class="blacking" @click="hideSelf"></div>
        <div class="callback-request-container" @click.self="hideSelf">
            <div class="callback-request">
                <div class="close" @click="hideSelf">
                    <img src="../images/close.png" alt="" />
                </div>
                <!-- slider -->
                <div class="slides swiper-container swiper-container_full">
                    <div class="swiper-wrapper">
                        <div
                            class="swiper-slide"
                            v-for="(image, j) in slides"
                            :key="`slide-${j}`"
                        >
                        <img class="slide-image" :src="image" alt="" />
                        </div>
                    </div>
                    <div class="swiper-button-prev swiper-button-prev_full"></div>
                    <div class="swiper-button-next swiper-button-next_full"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Mask from '../common/mask';
    import Mixin from '../common/mixin';
    import Swiper from 'swiper';

    export default {
        name: 'full-slider',
        emits: ["close"],
        components: {},
        directives: {Mask},
        mixins: [Mixin],
        props: {
            slides: Array,
            activeIndex: Number,
        },
        data() {
            return {
                swiper: null,
            };
        },
        mounted() {
            this.initSwiper();
        },
        methods: {
            initSwiper() {
                if (this.swiper) return;
                this.swiper = new Swiper(`.swiper-container_full`, {
                    slidesPerView: 1,
                    loop: false,
                    simulateTouch: true,
                    autoplay: false,
                    navigation: {
                        nextEl: ".swiper-button-next_full",
                        prevEl: ".swiper-button-prev_full",
                    },
                });
                
                this.swiper.slideTo(this.activeIndex, 500);
            },
            hideSelf() {
                this.$emit('close');
            }
        }
    }
</script>

<style scoped lang="scss">
.full-slider {
    z-index: 200;
}
    .mobile, .tablet {
        .callback-request {
            max-width: calc(100% - 25px);
        }
    }

    .slides {
        height: 100%;
    }

    .slide-image {
        height: 100%;
    }

    .callback-request-container {
        position: fixed;
        display: flex;
        width: 100vw;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 100;
    }

    .blacking {
        position: fixed;
        top: -100vh;
        left: -100vw;
        bottom: -100vw;
        right: -100vh;
        background: #000;
        opacity: 0.5;
        cursor: default;
        z-index: 80;
    }

    .callback-request {
        position: relative;
        margin: auto;
        z-index: 1200;
        width: 80%;
        max-width: 1920px;
        height: calc(100vw * 0.8 * 9 / 16);
        background: #f1f1f1;
        cursor: default;
        text-align: center;
        & > .close {
            position: absolute;
            z-index: 20;
            top: 20px;
            right: 25px;
            cursor: pointer;

            img {
                width: 20px;
                height: 20px;
            }
        }
        & > .callback-close {
            font-weight: bold;
            font-size: 20px;
            margin-bottom: 25px;
        }
        & > input {
            width: calc(100% - 20px);
            margin: 30px 10px 20px;
            height: 42px;
            text-align: center;
        }
        & > .send {
            font-weight: bold;
            text-transform: uppercase;
            font-size: 18px;

            width: calc(100% - 20px);
            margin: 0 10px;
            height: 42px;
            background-color: #ffcd0c;
            &:hover {
                background: #FFE100;
            }
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
    }
</style>
