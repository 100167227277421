const WEEK = 604800000;
const DAY = 86400000;
const today = new Date();

const MONTH_NAMES = [
  "января",
  "февраля",
  "марта",
  "апреля",
  "мая",
  "июня",
  "июля",
  "августа",
  "сентября",
  "октября",
  "ноября",
  "декабря",
];

export default {
  getNumericalName(number, variants) {
    const numerals = {
      days: ["день", "дня", "дней"],
      hours: ["час", "часа", "часов"],
      minutes: ["минута", "минуты", "минут"],
      seconds: ["секунда", "секунды", "секунд"],
      rubles: ["рубль", "рубля", "рублей"],
      bug: ["баг", "бага", "багов"],
    };
    if (!Array.isArray(variants)) {
      variants = numerals[variants] || numerals.bug;
    }
    let temp = number;
    while (number >= 10) {
      number = number % 10;
    }
    while (temp >= 100) {
      temp = temp % 10;
    }
    if (number > 0 && number < 5) {
      if (temp > 10 && temp < 15) {
        return variants[2];
      }
      if (number == 1) return variants[0];
      return variants[1];
    }
    return variants[2];
  },
  getNextDate(start) {
    let next = new Date(start.getTime());

    while (today > next) {
      next.setDate(next.getDate() + 3);
    }

    return next;
  },
  getDateString(date) {
    return (
      new Date(date).getDate() +
      " " +
      this.matchMonth(new Date(date).getMonth())
    );
  },
  matchMonth(month) {
    return MONTH_NAMES[month];
  },
};
